.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 17px;
  p {
    padding: 0 20px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  @media (max-height: 560px) {
    p {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }
  .dick {
    width: 20px;
    height: 40px;
    transform: rotate(55deg);
    position: relative;
    display: inline;
    left: 12px;
    top: 10px;
  }
  button {
    width: 335px;
    height: 66px;
  }
  @media (hover: hover) {
    button:hover {
      background-color: rgba(103, 176, 219, 1);
    }
  }
}
