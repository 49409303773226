@import url('./reset.scss');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@700&display=swap');

@font-face {
  font-family: 'PT Mono';
  src: url('../../shared/assets/fonts/ptmono/PTM55F.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'PT Mono';
  src: url('../../shared/assets/fonts/ptmono/PTM75F.ttf');
  font-weight: 700;
}

.App {
  font-family: PT Mono;
  text-align: center;
  width: 100%;
  height: 100%;

  h1 {
    font-size: 40px;
    line-height: 44.8px;
    font-weight: 700;
    text-transform: uppercase;
  }

  h2 {
    font-size: 30px;
    line-height: 33.6px;
    font-weight: 700;
    text-transform: uppercase;
  }

  // p {
  //   font-size: 22px;
  //   line-height: 24.64px;
  //   font-weight: 400;
  // }
  button {
    font-family: 'Ubuntu Mono';
    text-transform: uppercase;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    font-weight: 700;
    border: 2px solid black;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    color: black;
    width: 265px;
    height: 61px;
  }
}
