.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;

  .button {
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      position: relative;
      margin-left: 10px;
    }

    &.hide {
      visibility: hidden;
    }
  }

  @media (hover: hover) {
    .button:hover {
      background-color: rgba(103, 176, 219, 1);
    }
  }

  .button:disabled {
    cursor: auto;
    &:hover {
      background-color: white;
    }
  }
}

.censor {
  min-width: 93.3px;
  min-height: 93.3px;
  width: 93.3px;
  height: 93.3px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    position: absolute;
  }

  & .content {
    position: absolute;
    color: #000;
    text-align: center;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #000;
    font-family: 'Ubuntu Mono';
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  & .pluse {
    position: absolute;
  }
}

.text {
  color: var(--, #000);
  text-align: center;
  font-family: 'PT Mono';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 264px;
  height: 30px;
  flex-shrink: 0;
}

.input {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  height: 40px;
  width: 200px;
  font-family: 'PT Mono';

  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 26.4px */
  letter-spacing: 4.4px;
  text-align: center;

  &.error {
    border-bottom: 1px solid #f88;
    color: #f88;
  }
}

.input::placeholder {
  text-align: center;
  opacity: 0.3;
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 26.4px */
  letter-spacing: 4.4px;
}

.input::selection {
  border: none;
}

.input:focus {
  outline: none;

  &.error {
    border-bottom: 1px solid #f88;
    color: #e95e5e;
  }
}

.error-desc {
  width: 264px;
  min-height: 75px;
  height: 75px;
  display: block;

  & p {
    color: #e95e5e;
    text-align: center;
    font-family: 'PT Mono';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
