.container {
  background-color: rgba(180, 227, 255, 1);
  width: 100vw;
  height: 100%;

  .contentContainer {
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 1);
    border: 2px solid black;
    border-radius: 10px;
    width: 335px;
    margin: 0 auto;
    position: relative;
    top: calc(50% - 686px / 2);
    padding: 20px 30px;
    .counter {
      position: relative;
      margin: 0 auto;
      margin-top: 30px;
      width: 123px;
      height: 53px;
      background-color: white;
      border: 2px solid black;
      border-radius: 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;

      img {
        width: 35px;
      }
      div {
        font-size: 33px;
        margin-top: 8px;
        margin-left: 5px;
      }
    }
    h1 {
      font-size: 30px;
      line-height: 33.6px;
      margin-top: 20px;
      margin-bottom: 27px;
    }

    .onboard {
      font-size: 18px;
      line-height: 20.16px;
    }
    .buttonContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        margin-bottom: 15px;
        font-size: 25px;
      }
      @media (hover: hover) {
        button:hover {
          background-color: rgba(103, 176, 219, 1);
        }
      }
    }
    a {
      font-size: 25px;
      font-weight: 700;
      color: black;
    }
    p {
      margin-bottom: 25px;
    }
  }
}

@media (max-height: 690px) {
  .container {
    .contentContainer {
      top: calc(50% - 600px / 2);
      padding-top: 10px;
      .counter {
        margin-top: 20px;
      }
      p {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-height: 605px) {
  .container {
    .contentContainer {
      top: calc(50% - 280px);
      .counter {
        margin-top: 10px;
      }

      h1 {
        margin-bottom: 15px;
      }
      p {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

@media (max-height: 580px) {
  .container {
    .contentContainer {
      top: 10px;
      .buttonContainer {
        button {
          font-size: 20px;
          height: 50px;
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media (max-height: 540px) {
  .container {
    .contentContainer {
      h1 {
        font-size: 20px;
        margin: 10px 0;
      }
      p {
        font-size: 18px;
        line-height: 20px;
      }
      .counter {
        width: 100px;
        height: 45px;

        img {
          width: 28px;
        }
        div {
          font-size: 28px;
          margin-top: 7px;
          margin-left: 5px;
        }
      }
      a {
        font-size: 20px;
      }
    }
  }
}

.btn-leaderboard {
  text-underline-offset: 6px;
}
