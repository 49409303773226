html,
body {
  margin: 0;
  padding: 0;
  user-select: none;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}
