.container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  //   top: calc(50% - 155px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 37px;
  h2 {
    margin-bottom: 45px;
  }
  .items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 13px;
    width: 100%;

    a {
      user-select: none;
    }
    .BallItem {
      width: 75px;
      height: 115px;
      border: 2px solid black;
      border-radius: 10px;
      background-color: white;
      user-select: none;

      img {
        height: 85px;
        position: relative;
        top: 15px;
        user-select: none;
        pointer-events: none;
      }
    }
    @media (hover: hover) {
      .BallItem:hover {
        background-color: rgba(134, 210, 255, 1);
      }
    }
  }
}
