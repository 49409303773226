.Onboarding {
  background-color: rgba(180, 227, 255, 1);
  width: 100%;
  height: 100%;
  padding: 40px 20px 20px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .sound {
    position: fixed;
    z-index: 1;
  }
}
